import { graphql, useStaticQuery } from "gatsby";
import { Box, Card, Heading, Text } from "grommet";
import React from "react";

export default function Cards() {
  const { allMarkdownRemark } = useStaticQuery(
    graphql`
      query ServiceQuery {
        allMarkdownRemark(
          filter: { frontmatter: { templateKey: { eq: "service" } } }
        ) {
          edges {
            node {
              html
              frontmatter {
                title
              }
            }
          }
        }
      }
    `
  );

  return (
    <Box align="center" id="uslugi" gap="small">
      <Heading>Oferta</Heading>
      <Box justify="center" flex wrap id="uslugi" direction="row">
        {allMarkdownRemark.edges.map((edge, i) => (
          <Card
            margin="small"
            elevation="medium"
            key={i}
            align="center"
            width="medium"
            background="white"
            pad="medium"
          >
            <Heading level="3">{edge.node.frontmatter.title}</Heading>
            <Text
              dangerouslySetInnerHTML={{
                __html: edge.node.html,
              }}
              textAlign="justify"
            />
          </Card>
        ))}
      </Box>
    </Box>
  );
}
